import {
    webConfigDefaultValues,
    webConfigInputTypes,
    webConfigTypes,
} from '../constants/web-config.constants';

export interface IWebConfig {
    parameterName: string;
    parameterValue: string;
}

export interface IFormattedWebConfig {
    id: number;
    parameterName: string;
    parameterValue: string;
    type: string;
    defaultValue: string;
}

export class WebConfig {
    // default values of variables
    private _logo: string = webConfigDefaultValues.logo;
    private _loginSplashImage1: string = webConfigDefaultValues.loginSplashImage1;
    private _loginSplashImage2: string = webConfigDefaultValues.loginSplashImage2;
    private _copyright: string = webConfigDefaultValues.copyright;
    private _footer: string = webConfigDefaultValues.footer;
    private _primaryColor: string =
        webConfigDefaultValues.primaryColor;
    private _primaryTextColor: string = webConfigDefaultValues.primaryTextColor;
    private _secondaryColor: string =
        webConfigDefaultValues.secondaryColor;
    private _secondaryTextColor: string =
        webConfigDefaultValues.secondaryTextColor;

    constructor(options?: IWebConfig[]) {
        if (options) {
            this.set(options);
        }
    }

    set<T extends IWebConfig[]>(options: T): void {
        options.forEach((option) => this.setVariables(option));
    }

    setVariables(webConfig: IWebConfig) {
        // values received from backend
        // setting individual variables
        const { parameterName, parameterValue } = webConfig;
        this[`_${parameterName}`] = parameterValue;
    }

    set allWebConfig(webConfigs: IWebConfig[]) {
        webConfigs.map((webConfig) => this.setVariables(webConfig));
    }

    get logo() {
        return this._logo;
    }

    get loginSplashImage1() {
        return this._loginSplashImage1;
    }

    get loginSplashImage2() {
        return this._loginSplashImage2;
    }

    get copyright() {
        return this._copyright;
    }

    get footer() {
        return this._footer;
    }

    get primaryColor() {
        return this._primaryColor;
    }

    get secondaryColor() {
        return this._secondaryColor;
    }

    get primaryTextColor() {
        return this._primaryColor;
    }

    get secondaryTextColor() {
        return this._secondaryTextColor;
    }
    // get fontFamily() {
    //     return this._fontFamily;
    // }

    getWebConfigObj() {
        return {
            logo: this._logo,
            loginSplashImage1: this._loginSplashImage1,
            loginSplashImage2: this._loginSplashImage2,
            copyright: this._copyright,
            footer: this._footer,
            primaryColor: this._primaryColor,
            primaryTextColor: this._primaryTextColor,
            secondaryColor: this._secondaryColor,
            secondaryTextColor: this._secondaryTextColor,
            // fontFamily: this._fontFamily,
        };
    }
}
