import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  title: string; // Dialog title
  fields: { label: string; name: string; type: string; validators: any[], options?: any[], value?: any, disabled?: boolean, placeholder?: string, onChange?: any }[]; // Form fields
  actions: { label: string; value: any; color?: string; isSubmit?: boolean }[]; // Actions
}

@Component({
  selector: 'app-dialog-box',
  templateUrl: './dialog-box.component.html',
  styleUrls: ['./dialog-box.component.css']
})

export class DialogBoxComponent {
  form: FormGroup;
  parentData: any;
  renderForm: boolean = false;
  dialogBoxData: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DialogBoxComponent>,
    private fb: FormBuilder
  ) {
    if (data.title === 'Setup Security Questions') {
      this.initSecurityFormData(data);
      console.log("asdasda")
    }
  }

  closeDialog(result: any = null): void {
    this.dialogRef.close(result);
  }

  submit(): void {
    if (this.form.valid) {
      this.closeDialog(this.form.value);
    }
  }

  initSecurityFormData(data): void {
    this.dialogBoxData = data;
    this.parentData = data.data; // Parent data contains the available questions

    // Define the fields dynamically
    const fields = [
      {
        name: 'question1',
        type: 'select',
        placeholder: 'Select question',
        options: this.dialogBoxData.data,
        onChange: () => this.onSelectionChange(1),
      },
      {
        name: 'answer1',
        type: 'text',
        placeholder: 'Your answer',
        disabled: true,
        validators: [Validators.required]
      },
      {
        name: 'question2',
        type: 'select',
        placeholder: 'Select question',
        options: this.dialogBoxData.data,
        onChange: () => this.onSelectionChange(2),
      },
      {
        name: 'answer2',
        type: 'text',
        placeholder: 'Your answer',
        disabled: true,
        validators: [Validators.required]
      },
      {
        name: 'question3',
        type: 'select',
        placeholder: 'Select question',
        options: this.dialogBoxData.data,
        onChange: () => this.onSelectionChange(3),
      },
      {
        name: 'answer3',
        type: 'text',
        placeholder: 'Your answer',
        disabled: true,
        validators: [Validators.required]
      }
    ];

    const actions = [
      // change value to null
      { label: 'Cancel', },
      { label: 'Submit', isSubmit: true }
    ]

    // Dynamically create the form controls
    this.form = this.fb.group(
      fields.reduce((group, field) => {
        group[field.name] = field.disabled ? [{ value: '', disabled: true }, field.validators] : ['', field.validators || []];
        return group;
      }, {})
    );

    // Assign the updated fields back to data
    this.dialogBoxData.fields = fields;
    this.dialogBoxData.actions = actions;

    console.log(this.form);

  }

  onSelectionChange(questionNumber: number): void {
    this.form.get(`answer${questionNumber}`)?.reset();
    console.log("Selection Change");
    this.disableInput(questionNumber);
    const otherFields = [1, 2, 3].filter((num) => num !== questionNumber);

    otherFields.forEach((num) => {
      const fieldIndex = (num - 1) * 2;
      this.dialogBoxData.fields[fieldIndex].options = this.getAvailableQuestions(num);
    });

    console.log("Updated Data", this.dialogBoxData);
  }

  getAvailableQuestions(questionNumber: number): { label: string; value: string }[] {
    // Get the selected questions

    const selectedQuestions = questionNumber === 1 ? [this.form.get('question2')?.value, this.form.get('question3')?.value] :
      questionNumber === 2 ? [this.form.get('question1')?.value, this.form.get('question3')?.value] :
        questionNumber === 3 ? [this.form.get('question1')?.value, this.form.get('question2')?.value] : [];

    return this.dialogBoxData.data?.filter(question =>
      !selectedQuestions.includes(question.value)
    ) ?? [];
  }

  disableInput(questionNumber: number) {
    if (!this.form.get(`question${questionNumber}`).value) {
      this.form.get(`answer${questionNumber}`).disable();
    } else {
      this.form.get(`answer${questionNumber}`).enable();
    }
  }
}