<app-header 
    *ngIf="title" 
    [pageTitle]="title"
    [buttons]="buttons"
    (buttonClick)="handleHeaderBtnClick($event)">
</app-header>

<!-- <div class="page-header-wrapper">
    <mat-card class="mat-elevation-z5">
        <div class="page-header-container">
            <h1 class="header"> {{title}} </h1>
            <div class="btn-div">
                <button mat-raised-button class="secondary mat-elevation-z3" (click)="handleCancel()">Cancel</button>
                <button mat-raised-button class="primary mat-elevation-z3" (click)="onSubmit()"
                    [disabled]="form.invalid">Save</button>
            </div>
        </div>
    </mat-card>
</div> -->

<mat-card class="mat-elevation-z3 pb-0">
    <div [formGroup]="form" class="form">
        <div class="row" *ngFor="let row of formConfig">
            <div [ngClass]="('col-xs-12 col-sm-' + col.width + ' col-md-' + col.width)" *ngFor="let col of row">
                <mat-form-field
                    *ngIf="[fieldType.TEXT, fieldType.NUMBER, fieldType.EMAIL].includes(col.type)">
                    <mat-label>{{col.displayName}}</mat-label>
                    <input [type]="col.type" matInput [placeholder]="col.displayName" [formControlName]="col.fieldName">
                </mat-form-field>

                <!-- <mat-form-field *ngIf="col.type == 'text'">
                    <mat-label>{{col.displayName}}</mat-label>
                    <input type='text' matInput [placeholder]="col.displayName" [formControlName]="col.fieldName">
                </mat-form-field>

                <mat-form-field *ngIf="col.type == 'number'">
                    <mat-label>{{col.displayName}}</mat-label>
                    <input type='number' matInput [placeholder]="col.displayName" [formControlName]="col.fieldName">
                </mat-form-field>

                <mat-form-field *ngIf="col.type == 'email'">
                    <mat-label>{{col.displayName}}</mat-label>
                    <input type='email' matInput [placeholder]="col.displayName" [formControlName]="col.fieldName">
                </mat-form-field> -->

                <mat-form-field *ngIf="col.type == fieldType.DROPDOWN">
                    <mat-label>{{col.displayName}}</mat-label>
                    <div *ngIf="col.dropdownType == dropdownType.FIXED then fixedDropdown else notFixedDropdown">
                    </div>
                    <ng-template #fixedDropdown>
                        <mat-select [name]="col.displayName" [formControlName]="col.fieldName">
                            <mat-option value="">Select</mat-option>
                            <mat-option *ngFor="let option of col.options" [value]="option">
                                {{option}}
                            </mat-option>
                        </mat-select>
                    </ng-template>
                    <ng-template #notFixedDropdown>
                        <mat-select [name]="col.displayName" [formControlName]="col.fieldName">
                            <mat-option value="">Select</mat-option>
                            <mat-option *ngFor="let option of col.options" [value]="option.value">
                                {{option.displayName}}
                            </mat-option>
                        </mat-select>
                    </ng-template>
                </mat-form-field>

                <mat-form-field *ngIf="col.type == fieldType.DATE">
                    <mat-label>{{col.displayName}}</mat-label>
                    <input type="date" matInput [placeholder]="col.displayName" [name]="col.displayName">
                </mat-form-field>

                <div *ngIf="form.controls[col.fieldName].dirty">
                    <div *ngFor="let item of form.controls[col.fieldName].errors | keyvalue | slice : 0 : 1">
                        <div [ngSwitch]="item.key" class="field-error">
                            <p *ngSwitchCase="'required'">
                                This field is required.
                            </p>
                            <p *ngSwitchCase="'email'">
                                Please enter valid email address.
                            </p>
                            <p *ngSwitchCase="'maxlength'">
                                Maxmimum Length exceeded. Maxlength: {{item.value.requiredLength}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-card>