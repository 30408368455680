export const webConfigDefaultValues = {
    logo: 'https://360intdocuments.s3.amazonaws.com/Images/RoseWellness_logo.jpg',
    loginSplashImage1:
        'https://360intdocuments.s3.amazonaws.com/Images/RoseWellness_logo.jpg',
    loginSplashImage2:
        'https://360intdocuments.s3.amazonaws.com/Images/RoseWellness_logo.jpg',
    copyright: '© 2024 360Intellect. All Rights Reserved.',
    footer: '© 2024 360Intellect. All Rights Reserved.',
    primaryColor: '#f57e04',
    primaryTextColor: '#5cce0c',
    secondaryColor: '#828282',
    secondaryButtonTextColor: '#ffffff',
    secondaryTextColor: '#8787f7',
    // fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
};

export const fileWebConfigInputs = [
    'logo',
    'loginSplashImage1',
    'loginSplashImage2',
];

export const textWebConfigInputs = ['copyright', 'footer'];
// 'fontFamily'

export const colorPickerWebConfigInputs = [
    'primaryColor',
    'primaryTextColor',
    'secondaryColor',
    'secondaryTextColor',
];

export const allWebConfigNames = [
    'logo',
    'loginSplashImage1',
    'loginSplashImage2',
    'copyright',
    'footer',
    'primaryColor',
    'primaryTextColor',
    'secondaryColor',
    'secondaryTextColor',
    // 'fontFamily',
];

export const webConfigTypes = {
    FILE: fileWebConfigInputs,
    TEXT: textWebConfigInputs,
    COLOR_PICKER: colorPickerWebConfigInputs,
};

export const webConfigInputTypes = {
    FILE: 'file',
    TEXT: 'text',
    COLOR_PICKER: 'colorPicker',
};
