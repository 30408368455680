
import { IRow, ISection } from "../../../interfaces/";
// import { ISection } from "../../../interfaces"
import { Row } from "./row.model";

export class Section {
    private _sectionName: string;
    private _type: string;
    private _tableName: string;
    private _sortOrder: number;
    private _butnTheme: string;
    private _butnDisplay: string;
    private _butnName: string;
    private _rows: Array<Row> = [];

    constructor({ ...options }: ISection) {
        this.set(options);
    }

    set<T extends ISection>({ ...options }: T): void {
        this._sectionName = options.sectionName;
        this._type = options.type;
        this._tableName = options.tableName;
        this._sortOrder = options.sortOrder;
        this._butnTheme = options.butnTheme || 'primary';
        this._butnDisplay = options.butnDisplay
        this._butnName = options.butnName;

        // if (options.rows) {
        options.rows.forEach((elem: IRow) => {
            this._rows.push(new Row(elem));
        });
        // }
    }

    get sectionName() {
        return this._sectionName;
    }

    get type() {
        return this._type;
    }

    get tableName() {
        return this._tableName;
    }

    get sortOrder() {
        return this._sortOrder;
    }

    get butnTheme() {
        return this._butnTheme;
    }

    get butnDisplay() {
        return this._butnDisplay;
    }

    get butnName() {
        return this._butnName;
    }

    get rows() {
        return this._rows;
    }
    // get columnDisplayNames() {
    //     return this._columns.map(x => x.fieldName());
    // }

    // get data(args) {
    //     return arguments.length ? this._data = args : this._data;
    // }
}