<div class="page-header-wrapper">
    <mat-card class="mat-elevation-z3 pb-0">
        <div class="page-header-container" [ngClass]="{'display-center': isTitleCenter}">
            <div class="inner-header-container" *ngIf="isTitleEditable">
                <h1 class="primary header" *ngIf="!isEditTitle">{{pageTitle}}</h1>
                <input matInput type="text" [(ngModel)]="pageTitle" *ngIf="isEditTitle">
                <button mat-icon-button color="primary" (click)="isEditTitle = !isEditTitle" *ngIf="isTitleEditable && !isEditTitle">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button color="primary" (click)="isEditTitle = !isEditTitle" *ngIf="isTitleEditable && isEditTitle">
                    <mat-icon>cancel</mat-icon>
                </button>
            </div>
            <h1 class="primary header" *ngIf="!isTitleEditable">{{pageTitle}}</h1>

            <div class="btn-div">
                <button *ngFor="let button of buttons" mat-raised-button [color]="button.butnTheme"
                    (click)="onButtonClick(button.butnAction)" [disabled]="button.disabled">
                    {{button.butnName}}
                </button>
            </div>
        </div>

        <div class="page-filter-container" *ngIf="isDateFilter">
            <div class="row">
                <div class="col-xs-2" *ngFor="let field of formFields">
                    <mat-form-field>
                        <mat-label>{{ field.label }}</mat-label>
                        <ng-container [ngSwitch]="field.type">
                            <!-- Input Type: Date -->
                            <input *ngSwitchCase="'date'" type="date" matInput
                                [ngModel]="field.value | date:'yyyy-MM-dd'" (ngModelChange)="field.value = $event">
                            <!-- Input Type: Text -->
                            <input *ngSwitchCase="'text'" type="text" matInput [ngModel]="field.value"
                                (ngModelChange)="field.value = $event">
                            <!-- Input Type: Number -->
                            <input *ngSwitchCase="'number'" type="number" matInput [ngModel]="field.value"
                                (ngModelChange)="field.value = $event">
                            <!-- Input Type: Select -->
                            <mat-select *ngSwitchCase="'select'" [ngModel]="field.value"
                                (ngModelChange)="field.value = $event">
                                <mat-option *ngFor="let option of field.options" [value]="option">{{ option
                                    }}</mat-option>
                            </mat-select>
                        </ng-container>
                    </mat-form-field>
                </div>
                <div class="col-xs-2">
                    <button type="submit" color="primary" class="filter-btn" mat-raised-button (click)="onSearchClick()">{{searchButtonText}}</button>
                </div>
            </div>
        </div>

    </mat-card>
</div>