<div class="dialog-box">
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <mat-dialog-content>
        <form [formGroup]="form" class="row-no-gutters">
            <div class="col-xs-12" *ngFor="let field of dialogBoxData.fields">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label *ngIf="field.label">{{ field.label }}</mat-label>

                    <!-- Mat-Select for Dropdown -->

                    <mat-select *ngIf="field.type === 'select'" [formControlName]="field.name"
                        (ngModelChange)="field.onChange?.();" [placeholder]="field.placeholder || ''">
                        <mat-option *ngFor="let option of field.options || []" [value]="option.value">
                            {{ option.label }}
                        </mat-option>
                    </mat-select>

                    <!-- Input for Text Fields -->
                    <input *ngIf="field.type === 'text'" matInput [placeholder]="field.placeholder || ''"
                        [formControlName]="field.name" />


                    <!-- Error Handling -->
                    <!-- <mat-error *ngIf="form.get(field.name)?.hasError('required')">
                        {{ field.label }} is <strong>required</strong>
                    </mat-error> -->
                </mat-form-field>
            </div>

        </form>
    </mat-dialog-content>


    <div mat-dialog-actions>
        <div class="button-container">
            <ng-container *ngFor="let action of data.actions">
                <div class="col-xs-12">
                    <button [color]="action.color || 'primary'" type="submit" name="submit" mat-raised-button
                        [disabled]="action.isSubmit && form.invalid"
                        (click)="action.isSubmit ? submit() : closeDialog(null)">{{action.label}}
                    </button>
                </div>
            </ng-container>

            <!-- <div class="col-xs-12 mb-0 error-message" *ngIf="form.get(field.name)?.hasError('required')">
                <mat-icon>warning</mat-icon>{{ field.label }} is <strong>required</strong>
            </div> -->

        </div>
    </div>
</div>