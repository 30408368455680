import { IButton } from "src/app/shared/interfaces/button.interface";

export class Buttons {
    private _butnDisplay: string;
    private _butnName: string;
    private _butnTheme: string;
    private _butnAction: string;

    constructor({ ...options }: IButton) {
        this.set(options);
    }

    set<T extends IButton>({ ...options }: T): void {
        this._butnDisplay = options.butnDisplay
        this._butnName = options.butnName;
        this._butnTheme = options.butnTheme;
        this._butnAction = options.butnAction;
    }

    get butnDisplay() {
        return this._butnDisplay;
    }

    get butnTheme() {
        return this._butnTheme;
    }

    get butnName() {
        return this._butnName;
    }

    get butnAction() {
        return this._butnAction;
    }
}