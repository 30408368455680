import { FormControl, FormGroup, Validators } from '@angular/forms';

export class SFFieldMapper {
    sfField: string;
    sfFieldType: string;
    dbField: string;
    dbFieldType: string;

    static asFormGroup(album: SFFieldMapper): FormGroup {
        const fg = new FormGroup({
            sfField: new FormControl(album.sfField, Validators.required),
            sfFieldType: new FormControl(album.sfFieldType, Validators.required),
            dbField: new FormControl(album.dbField, Validators.required),
            dbFieldType: new FormControl({value :album.dbFieldType, disabled:true})
        });
        return fg;
    }
}
