<mat-expansion-panel hideToggle #panel="matExpansionPanel" (opened)="panelOpenState = true"
  (closed)="panelOpenState = false" class="mat-elevation-z1" [expanded]="expand">  
  <mat-expansion-panel-header expandedHeight="47px" collapsedHeight="47px">    
    <mat-panel-title [ngClass]="theme">
      <mat-icon *ngIf="!panelOpenState">add</mat-icon>
      <mat-icon *ngIf="panelOpenState">remove</mat-icon>
      <span class="header"><span class="title">{{ title }}&nbsp;</span>  {{ subtitle ? subtitle : ''}}
      </span>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <ng-content></ng-content>
</mat-expansion-panel>