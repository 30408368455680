import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IButton } from '../../interfaces/button.interface';
import { IFormField } from '../../interfaces/form-field.interface';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  @Input() pageTitle: string;
  @Input() isEditTitle: boolean = false;
  @Input() isTitleEditable: boolean = false;
  @Input() buttons: IButton[] = [];
  @Input() isTitleCenter: boolean = false;

  @Output() buttonClick = new EventEmitter<string>();
  @Output() editedTitleEvent = new EventEmitter<string>()

  @Input() isDateFilter: boolean = false;
  @Input() formFields: IFormField[] = [];
  @Input() searchButtonText: string = 'Search';
  @Output() formSubmitEvent = new EventEmitter<any>();

  ngOnChanges() {
    if (this.formFields) {
      this.onSearchClick();
    }
  }

  onEditTitle() {
    this.isEditTitle = !this.isEditTitle;
  }


  onButtonClick(action: string) {
    if (action === 'save') {
      this.editedTitleEvent.emit(this.pageTitle);
    }
    // to recive value before start running button functionality
    this.buttonClick.emit(action);
  }

  onSearchClick() {
    const formValues = this.formFields.reduce((acc, field) => {
      acc[field.label.replace(' ', '')] = field.value;
      return acc;
    }, {});
    this.formSubmitEvent.emit(formValues);
  }
}