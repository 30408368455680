import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { END_POINTS } from 'src/app/shared/constants/end-points.constants';
import { LoggedinUserService } from 'src/app/shared/services/loggedin-user/loggedin-user.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserSecurityService {

  constructor(private http: HttpClient,
    private loggedInUserSvc: LoggedinUserService,
  ) { }


  getSecurityQuestions() {
    const payload = { userId: this.loggedInUserSvc.user.userId };
    // console.log(payload)
    const uri = `${environment.NODE_SERVICE}${END_POINTS.BASE_URL}/user/getSecurityQuestions`;
    return this.http.post(uri, payload);
  }

  saveUserSecurityQuestions(securityQuestionID, securityAnswer) {
    const payload = {
      userId: this.loggedInUserSvc.user.userId,
      securityQuestionID,
      securityAnswer
    }

    // console.log(payload);

    const uri = `${environment.NODE_SERVICE}${END_POINTS.BASE_URL}/user/saveUserSecurityQuestions`;
    return this.http.post(uri, payload);
  }

  validateSecurityAnswer(userId, securityQuestionID, securityAnswer) {
    const payload = {
      userId,
      securityQuestionID,
      securityAnswer
    }

    // console.log("Validate Security Answer Payload", payload);

    const uri = `${environment.NODE_SERVICE}${END_POINTS.BASE_URL}/user/validateSecurityAnswer`;
    return this.http.post(uri, payload);
  }

  checkUsernameOrEmailExists(email) {
    const payload = {
      userName: "",
      email: email
    }

    // console.log(payload);
    const uri = `${environment.NODE_SERVICE}${END_POINTS.BASE_URL}/user/checkUsernameOrEmailExists`;
    return this.http.post(uri, payload);
  }

  getUserSecurityQuestions(userId?: any) {
    const payload = { userId: userId ? userId : this.loggedInUserSvc.user.userId };
    // console.log(payload);
    const uri = `${environment.NODE_SERVICE}${END_POINTS.BASE_URL}/user/getUserSecurityQuestions`;
    return this.http.post(uri, payload);
  }

  updateUserPassword(userId, newPassword) {
    const payload = {
      userId,
      newPassword
    }

    const uri = `${environment.NODE_SERVICE}${END_POINTS.BASE_URL}/user/updateUserPassword`;
    return this.http.post(uri, payload);
  }

  generate2FA(email){
    const uri = `${environment.NODE_SERVICE}${END_POINTS.BASE_URL}/user/generate2FA`;
    return this.http.post(uri, { email });
  }
}