import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { END_POINTS } from '../../constants/end-points.constants';
import { Menus } from '../../constants/roles.constant';
import { Menu } from '../../model/menu.model';
import { SubMenu } from '../../model/sub-menu.model';
import { Router } from '@angular/router';
import { BaseConfigComponent } from '../../components/pages/base-config/base-config.component';
import {
  MENU_TYPES,
  PORTAL_TYPE_ROUTES,
} from 'src/app/admin/constants/sidebar.constants';
import { DashboardMenuComponent } from '../../components/pages/dashboard-menu/dashboard-menu.component';

interface IMenuSubMenu {
  menu: string;
  subMenu: string;
  isEdit: boolean;
  isView: boolean;
  isDelete: boolean;
  defaultPage: string;
  pageId: string;
  path: string;
}

@Injectable({
  providedIn: 'root',
})
export class MenuSubmenuService {
  private static sidebar: Menu[] = [];
  private static dashboardList: Menu[] = [];
  // to let all components know that sidebar data is changed
  sidebarObserver = new Subject<Menu[]>();

  routePath = '';
  selectedMenu: Menu = new Menu();
  portalType = this.router.url.includes(PORTAL_TYPE_ROUTES.ADMIN_DASHBOARD)
    ? PORTAL_TYPE_ROUTES.ADMIN_DASHBOARD
    : PORTAL_TYPE_ROUTES.PORTAL;

  constructor(private http: HttpClient, private router: Router) {}

  getSidebar() {
    return MenuSubmenuService.sidebar;
  }

  getDashboardList() {
    return MenuSubmenuService.dashboardList;
  }

  getPortalSidebar() {
    const uri = `${environment.NODE_SERVICE}${END_POINTS.BASE_URL}/user/menu-submenu`;
    const payload = { pageType: MENU_TYPES.FORMS };

    return this.http.post(uri, payload).pipe(
      map((res: IMenuSubMenu[]) => {
        console.log('sidebar routes: ', res);

        const sidebar = this.processMenuAndSubmenu(res);
        // set the sidebar data
        MenuSubmenuService.sidebar = sidebar;

        // create the routes for sidebar
        this.createRoutes(sidebar);

        // let other compoenent know the sidebar is changed
        this.sidebarObserver.next(sidebar);
        return sidebar;
      })
    );
  }

  getPortalDashboards() {
    const uri = `${environment.NODE_SERVICE}${END_POINTS.BASE_URL}/user/menu-submenu`;
    const payload = { pageType: MENU_TYPES.DASHBOARD };

    return this.http.post(uri, payload).pipe(
      map((res: IMenuSubMenu[]) => {
        console.log('dashboard routes: ', res);

        const dashboardList = this.processMenuAndSubmenu(res);
        // set the dashboardList data
        MenuSubmenuService.dashboardList = dashboardList;

        // create the routes for sidebar
        this.createRoutes(dashboardList);
        return dashboardList;
      })
    );
  }

  getAdminSidebar() {
    this.routePath = PORTAL_TYPE_ROUTES.ADMIN_DASHBOARD;
    const uri = `${environment.NODE_SERVICE}${END_POINTS.BASE_URL}/admin/menu-submenu`;
    return this.http.get(uri).pipe(
      map((res: IMenuSubMenu[]) => {
        console.log(res);

        const sidebar = this.processMenuAndSubmenu(res);
        // set the sidebar data
        MenuSubmenuService.sidebar = sidebar;

        // let other compoenent know the sidebar is changed
        this.sidebarObserver.next(sidebar);
        return sidebar;
      })
    );
  }

  processMenuAndSubmenu(res: IMenuSubMenu[]) {
    let allMenus = [...new Set(res.map((rec) => rec.menu))];
    allMenus.sort();
    let sidebar = [];
    allMenus.map((menuName: string) => {
      // create menu
      let menu = this.processMenu(menuName);
      // get all the records with current menu name
      res
        .filter((rec) => rec.menu == menuName)
        .forEach((rec) => {
          if (rec.subMenu) {
            menu.expandable = true;
            let submenu = new SubMenu();
            submenu.title = rec.subMenu;
            if (this.portalType == PORTAL_TYPE_ROUTES.ADMIN_DASHBOARD) {
              submenu.path = `/${this.routePath}/${this.getAdminMenuPath(submenu.title)}`;
            } else if (this.portalType == PORTAL_TYPE_ROUTES.PORTAL) {
              submenu.path = `/${this.routePath}/${rec.path}`;
            }
            submenu.defaultPage = rec.defaultPage;
            submenu.pageId = rec.pageId;

            // if submenu.path is empty, then don't add it in menu
            if (submenu.path) {
              menu.addSubMenu(submenu);
            }
          } else {
            menu.expandable = false;
            if (this.portalType == PORTAL_TYPE_ROUTES.ADMIN_DASHBOARD) {
              menu.path = `/${this.routePath}/${this.getAdminMenuPath(menu.title)}`;
            } else if (this.portalType == PORTAL_TYPE_ROUTES.PORTAL) {
              menu.path = `/${this.routePath}/${rec.path}`;
            }
            menu.defaultPage = rec.defaultPage;
            menu.pageId = rec.pageId;
          }
        });
      // if menu is expandable then sort the submenus
      if (menu.expandable) {
        menu.subMenus = menu.subMenus.sort((a, b) => (a.title > b.title) ? 1 : -1)
      }
      // if we have expandable menu with no submenu then don't use menu in sidebar
      // if we have non-expandable menu with path == '' then don't use menu in sidebar
      if (
        (menu.expandable && menu.subMenus.length != 0) ||
        (!menu.expandable && menu.path != '')
      ) {
        sidebar.push(menu);
      }
    });
    return sidebar;
  }

  processMenu(menuName: string): Menu {
    let menu = new Menu();
    menu.title = menuName;
    switch (menuName) {
      case Menus.dashboard:
        menu.iconClass = 'fa fa-tachometer';
        break;
      case Menus.admin:
        menu.iconClass = 'fa fa-user-circle-o';
        break;
      case Menus.contacts:
        menu.iconClass = 'fa fa-address-book';
        break;
      case Menus.survey:
        menu.iconClass = 'fa fa-book';
        break;
      case Menus.forms:
        menu.iconClass = 'fa fa-wpforms';
        break;
      case Menus.home:
        menu.iconClass = 'fa fa-home';
        break;
      case Menus.dataDictionary:
        menu.iconClass = 'fa fa-book';
        break;
      case Menus.menuSetup:
        menu.iconClass = 'fa fa-bars';
        break;
      case Menus.accessControl:
        menu.iconClass = 'fa fa-bars';
        break;
      case Menus.changeHistory:
        menu.iconClass = 'fa fa-history';
        break;
      case Menus.salesforceIntegration:
        menu.iconClass = 'fa fa-plug';
        break;
      case Menus.uiConfiguration:
        menu.iconClass = 'fa fa-cogs';
        break;
    }
    return menu;
  }

  getAdminMenuPath(menuName) {
    let path = '';
    switch (menuName) {
      case Menus.dashboard:
        path = 'dashboard';
        break;
      case Menus.home:
        path = '';
        break;
      case Menus.forms:
        path = 'forms';
        break;
      case Menus.dataDictionary:
        path = 'data-dictionary';
        break;
      case Menus.menuSetup:
        path = 'menu-setup';
        break;
      case Menus.changeHistory:
        path = 'change-history';
        break;
      case Menus.accessControl:
        path = 'access-control';
        break;
      case Menus.salesforceIntegration:
        path = 'sf';
        break;
      case Menus.uiConfiguration:
        path = 'ui-configuration';
        break;
    }
    return path;
  }

  createRoutes(newRoutes: Menu[]) {
    // get routes other than redirect route
    const allRoutes = this.router.config.slice(0, -1);
    // last route will be redirect route
    const redirectRoute = this.router.config.pop();

    // current route which we add while login
    const currentRouter = allRoutes.pop();
    const pathList = currentRouter.children.map((route) => route.path);

    newRoutes.map((menu) => {
      if (menu.expandable) {
        menu.subMenus.map((submenu) => {
          const path = submenu.path.replace(`/${this.routePath}/`, '');
          if (!pathList.includes(path)) {
            currentRouter.children.push({
              path: path,
              component: BaseConfigComponent,
            });
            pathList.push(submenu.path);
          }
        });
      } else {
        const path = menu.path.replace(`/${this.routePath}/`, '');
        if (!pathList.includes(path)) {
          currentRouter.children.push({
            path: path,
            component: BaseConfigComponent,
          });
          pathList.push(menu.path);
        }
      }
    });

    // dashboard list page
    if (!pathList.includes('dashboard')) {
      currentRouter.children.push({
        path: 'dashboard',
        component: DashboardMenuComponent,
      });
    }

    // after adding the children route reset the router config
    this.router.resetConfig([...allRoutes, currentRouter, redirectRoute]);
  }
}
