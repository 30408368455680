import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  selectedQuestionForm: FormGroup;
  questions: any[] = [];
  showQuestionsForm = false;
  constructor(
    private fb: FormBuilder) { }

  ngOnInit(): void {
  }






}
