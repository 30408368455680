import { IPanel, ISection } from "../../../interfaces";
import { Section } from "../section.model";

export class Panel {
    private _panelName: string;
    private _panelMessage: string;
    private _hideEmptyGrid: string;
    private _collapsePanel: string;    
    private _panelTheme: string = 'primary';
    private _sections:Array<Section>=[];

    constructor({ ...options }: IPanel) {
        this.set(options);
    }

    set<T extends IPanel>({ ...options }: T): void {
        this._panelName = options.panelName;
        this._panelMessage = options.panelMessage;
        this._hideEmptyGrid = options.hideEmptyGrid;
        this._collapsePanel = options.collapsePanel;
        this._panelTheme = options.panelTheme || 'primary'; // default theme
        
        options.sections.forEach((elem:ISection) => {
            this._sections.push(new Section(elem));
        });
    }

    get panelName() {
        return this._panelName;
    }

    get panelMessage() {
        return this._panelMessage;
    }

    get hideEmptyGrid() {
        return this._hideEmptyGrid === 'Yes';
    }

    get collapsePanel() {
        return this._collapsePanel === 'Yes';
    }

    get sections() {
        return this._sections;
    }

    get panelTheme() {
        return this._panelTheme;
    }
}